/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';

export const CREATE_BOOKING = 'CREATE_BOOKING';
export const CHANGE_ROOM = 'CHANGE_ROOM';
export const AUTO_ASSIGN_GUESTS = 'AUTO_ASSIGN_GUESTS';
export const JOIN_REMEMBERED = 'JOIN_REMEMBERED';
export const INCLUDE_BREAKFAST = 'INCLUDE_BREAKFAST';
export const INCLUDE_SMOKING = 'INCLUDE_SMOKING';
export const INCLUDE_PET_FRIENDLY = 'INCLUDE_PET_FRIENDLY';
export const ADD_SUNDRY_ITEM = 'ADD_SUNDRY_ITEM';

export const FAIL_GUEST_PROFILE = 'FAIL_GUEST_PROFILE';
export const SUCCESS_GUEST_PROFILE = 'SUCCESS_GUEST_PROFILE';

export const CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD';

export const FAIL_CREDIT_CARD = 'FAIL_CREDIT_CARD';
export const SUCCESS_CREDIT_CARD = 'SUCCESS_CREDIT_CARD';

export const FAIL_WALLET = 'FAIL_WALLET';
export const SUCCESS_WALLET = 'SUCCESS_WALLET';

export const APPLY_PROMO_CODE = 'APPLY_PROMO_CODE';
export const SUCCESS_PROMO_CODE = 'SUCCESS_PROMO_CODE';
export const FAIL_PROMO_CODE = 'FAIL_PROMO_CODE';

export const SUBMIT_BOOKING = 'SUBMIT_BOOKING';
export const SUCCESS_SUBMIT_BOOKING = 'SUCCESS_SUBMIT_BOOKING';
export const FAIL_SUBMIT_BOOKING = 'FAIL_SUBMIT_BOOKING';

export const RESEND_BOOKING_CONFIRMATION = 'RESEND_BOOKING_CONFIRMATION';
export const SUCCESS_RESEND_BOOKING_CONFIRMATION =
  'SUCCESS_RESEND_BOOKING_CONFIRMATION';
export const FAIL_RESEND_BOOKING_CONFIRMATION =
  'FAIL_RESEND_BOOKING_CONFIRMATION';

export const USER_INIT = 'USER_INIT';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SUCCESS_USER_LOGIN = 'SUCCESS_USER_LOGIN';
export const FAIL_USER_LOGIN = 'FAIL_USER_LOGIN';

export const USER_FB_LOGIN = 'USER_FB_LOGIN';

export const USER_SIGN_UP = 'USER_SIGN_UP';
export const SUCCESS_USER_SIGN_UP = 'SUCCESS_USER_SIGN_UP';
export const FAIL_USER_SIGN_UP = 'FAIL_USER_SIGN_UP';

export const USER_UPDATE = 'USER_UPDATE';
export const SUCCESS_USER_UPDATE = 'SUCCESS_USER_UPDATE';
export const FAIL_USER_UPDATE = 'FAIL_USER_UPDATE';

export const OPEN_SIGN_IN_MODAL = 'OPEN_SIGN_IN_MODAL';
export const CLOSE_SIGN_IN_MODAL = 'CLOSE_SIGN_IN_MODAL';
export const OPEN_SIGN_UP_MODAL = 'OPEN_SIGN_UP_MODAL';
export const CLOSE_SIGN_UP_MODAL = 'CLOSE_SIGN_UP_MODAL';
export const OPEN_NEWSLETTER_MODAL = 'OPEN_NEWSLETTER_MODAL';
export const CLOSE_NEWSLETTER_MODAL = 'CLOSE_NEWSLETTER_MODAL';
export const OPEN_ERROR_MODAL = 'OPEN_ERROR_MODAL';
export const CLOSE_ERROR_MODAL = 'CLOSE_ERROR_MODAL';

export const OPEN_DELETE_ACCOUNT_MODAL = 'OPEN_DELETE_ACCOUNT_MODAL';
export const CLOSE_DELETE_ACCOUNT_MODAL = 'CLOSE_DELETE_ACCOUNT_MODAL';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SUCCESS_FORGOT_PASSWORD = 'SUCCESS_FORGOT_PASSWORD';
export const FAIL_FORGOT_PASSWORD = 'FAIL_FORGOT_PASSWORD';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SUCCESS_RESET_PASSWORD = 'SUCCESS_RESET_PASSWORD';
export const FAIL_RESET_PASSWORD = 'FAIL_RESET_PASSWORD';

export const INIT_CHANGE_PASSWORD = 'INIT_CHANGE_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SUCCESS_CHANGE_PASSWORD = 'SUCCESS_CHANGE_PASSWORD';
export const FAIL_CHANGE_PASSWORD = 'FAIL_CHANGE_PASSWORD';

export const NEW_PASSWORD = 'NEW_PASSWORD';
export const SUCCESS_NEW_PASSWORD = 'SUCCESS_NEW_PASSWORD';
export const FAIL_NEW_PASSWORD = 'FAIL_NEW_PASSWORD';

export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE';
export const CONFIRM_FLASH_MESSAGE = 'CONFIRM_FLASH_MESSAGE';
export const DISMISS_FLASH_MESSAGE = 'DISMISS_FLASH_MESSAGE';
export const REMOVE_FLASH_MESSAGE = 'REMOVE_FLASH_MESSAGE';
export const SHOW_OVERLAY_ONLY = 'SHOW_OVERLAY_ONLY';

export const START_SUBSCRIBE_NEWS = 'START_SUBSCRIBE_NEWS';
export const SUCCESS_SUBSCRIBE_NEWS = 'SUCCESS_SUBSCRIBE_NEWS';
export const FAIL_SUBSCRIBE_NEWS = 'FAIL_SUBSCRIBE_NEWS';

export const GET_BOOKINGS_START = 'GET_BOOKINGS_START';
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const GET_BOOKINGS_FAIL = 'GET_BOOKINGS_FAIL';

export const DELETE_BOOKINGS_START = 'DELETE_BOOKINGS_START';
export const DELETE_BOOKINGS_SUCCESS = 'DELETE_BOOKINGS_SUCCESS';
export const DELETE_BOOKINGS_FAIL = 'DELETE_BOOKINGS_FAIL';

export const OPEN_RESEND_BOOKING_MODAL = 'OPEN_RESEND_BOOKING_MODAL';
export const CLOSE_RESEND_BOOKING_MODAL = 'CLOSE_RESEND_BOOKING_MODAL';

export const START_CHECK_IN = 'START_CHECK_IN';
export const FAIL_START_CHECK_IN = 'FAIL_START_CHECK_IN';

export const CHECK_IN_FAIL_CREDIT_CARD = 'CHECK_IN_FAIL_CREDIT_CARD';
export const CHECK_IN_SUCCESS_CREDIT_CARD = 'CHECK_IN_SUCCESS_CREDIT_CARD';

export const CHECK_IN_PAYMENT_START = 'CHECK_IN_PAYMENT_START';
export const CHECK_IN_PAYMENT_SUCCESS = 'CHECK_IN_PAYMENT_SUCCESS';
export const CHECK_IN_PAYMENT_FAIL = 'CHECK_IN_PAYMENT_FAIL';

export const CHECK_IN_VERIFY_DOCUMENT = 'CHECK_IN_VERIFY_DOCUMENT';

export const CHECK_IN_ROOM_PREFERENCES = 'CHECK_IN_ROOM_PREFERENCES';

export const CHECK_IN_EDIT_GUEST_SUCCESS = 'CHECK_IN_EDIT_GUEST_SUCCESS';
export const CHECK_IN_EDIT_GUEST_FAIL = 'CHECK_IN_EDIT_GUEST_FAIL';

export const USER_BOOKING = 'USER_BOOKING';

export const HOTEL_INFO = 'HOTEL_INFO';
export const CLEAR_HOTEL_INFO = 'CLEAR_HOTEL_INFO';

export const OPEN_MEETINGROOM_MODAL = 'OPEN_MEETINGROOM_MODAL';
export const CLOSE_MEETINGROOM_MODAL = 'CLOSE_MEETINGROOM_MODAL';

export const CHECK_IN_UPLOADID_SUCCESS = 'CHECK_IN_UPLOADID_SUCCESS';
export const CHECK_IN_UPLOADID_FAIL = 'CHECK_IN_UPLOADID_FAIL';
